import {
  AxiosError,
  type TPurchase,
  type TSellerAddress,
  type TSubPurchase,
  deleteSubpurchaseById,
} from "@/api";
import { PURCHASES, SUPPLIERS, SUPPLIER_PURCHASES } from "@/config/resources";
import { useAdmin } from "@/hooks/admin";
import { usePaymentMethods } from "@/hooks/payment-methods";
import { useDeliveryProviders } from "@/hooks/purchase";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Link as MUILink,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Link,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useGetOne,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  SubpurchaseGenInvoiceButton,
  SubpurchaseInvoicesButton,
} from "./actions/subpurchase-invoices";
import { SubpurchaseTrackingCodesAction } from "./actions/subpurchase-tracking-codes";
import { SubpurchaseTrackingButton } from "./actions/subpurchase-tracking";
import { SubpurchaseDeliveryStatusButton } from "./actions/subpurchase-delivery-status";
import { SubpurchaseReturnProductsAction } from "./actions/subpurchase-return-products";
import { isFinishedPurchase } from "../shared/actions-disabled";
import { SubpurchasePaymentMethodAction } from "./actions/subpurchase-payment-method";
// import { SubpurchasePaymentStatusButton } from "./actions/payment-status";
import { SubpurchasePaymentLinkButton } from "./actions/subpurchase-payment-link";
import { SubpurchaseRemission } from "./actions/subpurchase-remission";
import { TimeZoneDate } from "@/components/TimezoneDate";
import { mdiDelete, mdiOpenInNew } from "@mdi/js";
import { useState } from "react";
import { SubpurchaseTrackingCodesHistoryModalAction } from "./actions/subpurchase-tracking-codes-history";
import { deliveryStatusRendering, paymentStatus } from "@/data/purchases";
import { ReusableProductsTable } from "./_reusable-products-table";
import { SubpurchaseSupplierComments } from "./actions/subpurchase-supplier-comments";
import { formatCurrency } from "@/utils/currency";
import { SubpurchaseVoucherAction } from "./actions/subpurchase-voucher";
import { i18nProvider } from "@/providers/i18n";

export const PurchaseSubpurchases = () => {
  const purchase = useRecordContext<TPurchase>();
  const paymentMethods = usePaymentMethods();
  const deliveryProviders = useDeliveryProviders();
  const { hasPermission, isAdmin } = useAdmin();

  const isFromSuppliersPortal = purchase.origin?.name === "suppliers";

  const canUpdateDeliveryFromSuppliersPortal = hasPermission(
    "subpurchase.fromSupplier.updateDelivery"
  );

  if (!purchase) return null;

  return (
    <>
      {purchase?.subPurchases?.map((subpurchase: TSubPurchase) => (
        <RecordContextProvider value={subpurchase} key={subpurchase.id}>
          <Card
            variant="outlined"
            className="subpurchase-card"
            sx={{ width: "100%" }}
          >
            <CardContent>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap-reverse"
              >
                <Box>
                  Subpedido No.{" "}
                  {subpurchase.isFromSupplier ? (
                    <>
                      <Link
                        to={`/${SUPPLIER_PURCHASES}/${subpurchase.id}/show`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {subpurchase.subPurchaseNumber}
                      </Link>{" "}
                      <Chip label="Pedido de proveedor" size="small" />
                    </>
                  ) : (
                    subpurchase.subPurchaseNumber
                  )}
                </Box>
                <Stack direction="row" justifyContent="end">
                  {(subpurchase.paymentMethod === "UPON_DELIVERY" ||
                    subpurchase.paymentMethod === "SUPPLIER_PAY" ||
                    subpurchase.paymentMethod === "CREDIT") && (
                    <SubpurchaseVoucherAction />
                  )}

                  {(!isFromSuppliersPortal || isAdmin) && (
                    <>
                      <SubpurchaseInvoicesButton />
                      <SubpurchaseTrackingCodesAction />
                    </>
                  )}

                  {(!isFromSuppliersPortal ||
                    canUpdateDeliveryFromSuppliersPortal) && (
                    <>
                      <SubpurchaseTrackingButton />
                      <SubpurchaseDeliveryStatusButton />
                    </>
                  )}

                  {(!isFromSuppliersPortal || isAdmin) && (
                    <SubpurchaseReturnProductsAction purchase={purchase} />
                  )}

                  {(!isFromSuppliersPortal || isAdmin) &&
                    !isFinishedPurchase(purchase) && (
                      <>
                        <SubpurchasePaymentMethodAction />
                        {/* <SubpurchasePaymentStatusButton /> */}
                        <SubpurchasePaymentLinkButton />
                      </>
                    )}

                  {hasPermission("subpurchase.action.delete") &&
                    (!isFromSuppliersPortal || isAdmin) &&
                    !isFinishedPurchase(purchase) && (
                      <SubpurchaseDeleteButton />
                    )}

                  {/* {(!isFromSuppliersPortal || isAdmin) && ( */}
                  <SubpurchaseGenInvoiceButton
                    manualInvoice={!!purchase.manualInvoice}
                  />
                  {/* )} */}
                  <SubpurchaseRemission
                    record={{
                      ...subpurchase,
                      client: purchase.client,
                    }}
                  />
                </Stack>
              </Stack>
              <Grid container>
                <Grid item xs={6} md={4} lg={3}>
                  <Box sx={{ my: 1 }} fontSize="small">
                    Fecha de creación:{" "}
                    <b>
                      <TimeZoneDate date={subpurchase.createdAt} />
                    </b>
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <Box sx={{ my: 1 }} fontSize="small">
                    Proveedor logístico:{" "}
                    <b>
                      {deliveryProviders?.find(
                        (p) => p.code === subpurchase.deliveryProvider
                      )?.name ?? subpurchase.deliveryProvider}
                    </b>
                    &nbsp;
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  lg={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography sx={{ my: 1 }} fontSize="small">
                    Guía: {setTrackingCodeLink(subpurchase)}&nbsp;
                  </Typography>
                  <SubpurchaseTrackingCodesHistoryModalAction />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <Box sx={{ my: 1 }} fontSize="small">
                    Estado de envío:&nbsp;
                    <Chip
                      label={
                        deliveryStatusRendering.find(
                          (s) => s.code === subpurchase.deliveryStatus
                        )?.name
                      }
                      size="small"
                    />
                  </Box>
                </Grid>
                {subpurchase.deliveryStatus === "DELIVERED" && (
                  <Grid item xs={6} md={4} lg={3}>
                    <Box sx={{ my: 1 }} fontSize="small">
                      Fecha de entregado:{" "}
                      <b>
                        <TimeZoneDate date={subpurchase.deliveredAt} />
                      </b>
                    </Box>
                  </Grid>
                )}
                {subpurchase?.warehouseId && (
                  <Grid item xs={12} md={8} lg={6}>
                    <Box sx={{ my: 1 }} fontSize="small">
                      Bodega de despacho:&nbsp;
                      <Chip
                        label={
                          <WarehouseDescription
                            supplierId={subpurchase?.supplierId ?? ""}
                            warehouseId={subpurchase.warehouseId}
                          />
                        }
                        size="small"
                      />
                    </Box>
                  </Grid>
                )}
                {subpurchase?.evidenceLink && (
                  <Grid item xs={6} md={4} lg={3}>
                    <Box sx={{ my: 1 }} fontSize="small">
                      Evidencia de entregado:&nbsp;
                      <Chip
                        label={
                          <MUILink
                            href={subpurchase.evidenceLink}
                            target="_blank"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            Abrir{" "}
                            <SvgIcon
                              sx={{ display: "inline" }}
                              fontSize="small"
                            >
                              <path d={mdiOpenInNew} />
                            </SvgIcon>
                          </MUILink>
                        }
                        size="small"
                      />
                    </Box>
                  </Grid>
                )}
                {subpurchase.isFromSupplier && (
                  <>
                    <Grid item xs={6} md={4} lg={3}>
                      <Box sx={{ my: 1 }} fontSize="small">
                        Proveedor:&nbsp;
                        <RecordContextProvider value={subpurchase}>
                          <ReferenceField
                            source="supplierId"
                            reference={SUPPLIERS}
                            link={
                              hasPermission("supplier.view") ? "show" : false
                            }
                          >
                            <TextField source="name" />
                          </ReferenceField>
                        </RecordContextProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                      <Box sx={{ my: 1 }} fontSize="small">
                        Estado de proveedor:&nbsp;
                        <Chip
                          label={
                            deliveryStatusRendering.find(
                              (s) => s.code === subpurchase.supplierStatus
                            )?.name
                          }
                          size="small"
                        />
                        <SubpurchaseSupplierComments
                          subpurchase={subpurchase}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
                {hasPermission("subpurchase.view.deliveryCost") && (
                  <Grid item xs={6} md={4} lg={3}>
                    <Box sx={{ my: 1 }} fontSize="small">
                      Costo de envío:&nbsp;
                      <Chip
                        label={formatCurrency(subpurchase.deliveryCost ?? 0)}
                        size="small"
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item xs={6} md={4} lg={3}>
                  <Box sx={{ my: 1 }} fontSize="small">
                    Estado de pago:&nbsp;
                    <Chip
                      label={
                        paymentStatus.find(
                          (s) => s.code === subpurchase.paymentStatus
                        )?.name
                      }
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <Box sx={{ my: 1 }} fontSize="small">
                    Método de pago:&nbsp;
                    {subpurchase.paymentMethod && (
                      <Chip
                        label={i18nProvider.translate(
                          `resources.${PURCHASES}.fields.paymentMethods.${subpurchase.paymentMethod}`
                        )}
                        size="small"
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                  <Box sx={{ my: 1 }} fontSize="small">
                    Enlace de pago:&nbsp;
                    <Chip
                      label={
                        subpurchase.paymentLink ? (
                          <MUILink
                            href={subpurchase.paymentLink}
                            target="_blank"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            Abrir{" "}
                            <SvgIcon
                              sx={{ display: "inline" }}
                              fontSize="small"
                            >
                              <path d={mdiOpenInNew} />
                            </SvgIcon>
                          </MUILink>
                        ) : (
                          "-"
                        )
                      }
                      size="small"
                    />
                  </Box>
                </Grid>
              </Grid>
              <ReusableProductsTable
                source="products"
                key={subpurchase.id}
                mode="subpurchase"
              />
            </CardContent>
          </Card>
        </RecordContextProvider>
      ))}
    </>
  );
};

const SubpurchaseDeleteButton = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);

  const hasInvoices = record?.invoices?.length;

  const deleteSubpurchase = async () => {
    if (!confirm("¿Está seguro que desea eliminar este subpedido?")) return;
    setLoading(true);
    try {
      await deleteSubpurchaseById(`${record.id}`);
      notify("Se ha eliminado el subpedido", { type: "success" });
      refresh();
    } catch (error) {
      const message =
        (error instanceof AxiosError && error.response?.data?.message) ||
        "Ocurrió un error";
      notify(`Error de servidor: ${message}`, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div>
      <Tooltip title="Eliminar subpedido">
        <div>
          <IconButton
            color="primary"
            onClick={deleteSubpurchase}
            disabled={Boolean(
              hasInvoices ||
                loading ||
                record.supplierStatus === "REMISSION_GENERATED"
            )}
          >
            <SvgIcon>
              <path d={mdiDelete} />
            </SvgIcon>
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

type TWarehouseDescription = { supplierId: string; warehouseId: string };
const WarehouseDescription = ({
  supplierId,
  warehouseId,
}: TWarehouseDescription) => {
  const { data: supplierData } = useGetOne(SUPPLIERS, {
    id: supplierId,
  });

  const address = supplierData?.addresses?.find(
    ({ id }: TSellerAddress) => id === warehouseId
  );

  if (!address) {
    return null;
  }

  return (
    <>{`${address?.addressLine1 ?? "-"}, ${address?.addressLine2 ?? "-"};
${address?.city ?? "-"}, ${address?.country ?? "-"}`}</>
  );
};

const setTrackingCodeLink = (record: TSubPurchase) => {
  if (record.trackingLink) {
    return (
      <MUILink href={record.trackingLink} target="_blank">
        {record.trackingCode || "Enlace"}
      </MUILink>
    );
  }

  return "-";
};
