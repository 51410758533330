import { getInstance } from "../../core/axios-gateway";
import type { TLocation } from "../../types/location";
import type {
  TPrePurchase,
  TPrePurchasePayload,
  TPurchase,
  TPurchasePayload,
  TRequestPurchaseComment,
  TRequestPurchaseDeliveryStatus,
  TRequestPurchasePaymentMethod,
  TRequestPurchasePaymentStatus,
  TRequestPurchasePaymentVouchers,
  TRequestPurchaseProduct,
  TRequestPurchaseProductsUpdate,
  TRequestPurchaseProductTracking,
  TRequestPurchaseShipping,
  TRequestSubPurchaseProductUpdate,
  TResponsePurchaseComment,
  TResponsePurchasePaymentVouchers,
} from "../../types/purchase";
import type { TRestListData, TRestListParams } from "../../types/rest";
import type { AxiosRequestHeaders } from "axios";

const BASE_PATH = "/api/orders";
const BASE_PATH_V2 = "/api/orders/v2";

export { cancelPurchase, getPurchaseCancellationReasons } from "./cancellation";
export * from "./subpurchases";
export * from "./delivery-providers";

export const deleteSubpurchaseById = async (subPurchaseId: string) => {
  return getInstance().delete(`${BASE_PATH}/sub-purchases/${subPurchaseId}`);
};

export const getPurchases = async (params: TRestListParams) => {
  return getInstance()
    .get<TRestListData<TPurchase>>(BASE_PATH, { params })
    .then((res) => res.data);
};

function isUUID(value: string) {
  // Patrón para validar UUID (versión 1 a 5)
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
}

export const getPurchaseById = async (purchaseId: string) => {
  if (!isUUID(purchaseId)) {
    return;
  }
  return getInstance()
    .get<TPurchase>(`${BASE_PATH}/id/${purchaseId}`)
    .then((res) => res.data);
};

export const getPurchasesBySellerId = async (sellerId: string) => {
  return getInstance()
    .get<TPurchase[]>(`${BASE_PATH}/${sellerId}`)
    .then((res) => res.data);
};

export const createPurchase = async (
  purchase: Partial<TPurchasePayload>,
  headers?: AxiosRequestHeaders
) => {
  return getInstance()
    .post<TPurchase>(BASE_PATH_V2, purchase, { headers })
    .then((res) => res.data);
};

export const updatePurchaseProducts = async (
  purchaseId: string,
  data: TRequestPurchaseProductsUpdate
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/updateProducts`, data)
    .then((res) => res.data);
};

export const updateProductTracking = async (
  purchaseId: string,
  data: TRequestPurchaseProductTracking[]
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/updateTracking`, data)
    .then((res) => res.data);
};

export const postPurchasePaymentLink = async (purchaseId: string) => {
  return getInstance()
    .post<TPurchase>(`${BASE_PATH}/paymentLink`, { purchaseId })
    .then((res) => res.data);
};

export const createPrePurchasev3 = async (data: TPrePurchasePayload) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/v3/prepurchasedetail`, data)
    .then((res) => res.data);
};

export const createPrePurchase = async (data: TPrePurchasePayload) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/v4/prepurchasedetail`, data, {
      headers: {
        "x-log": "omit",
      },
    })
    .then((res) => res.data);
};

export const createPrePurchaseV5 = async (
  data: TPrePurchasePayload,
  headers: AxiosRequestHeaders = {}
) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/v5/prepurchasedetail`, data, {
      headers: {
        "x-log": "omit",
        ...headers,
      },
    })
    .then((res) => res.data);
};

export const updatePurchasePaymentStatus = async (
  purchaseId: string,
  answer: "approve" | "reject"
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/${answer}`)
    .then((res) => res.data);
};

export const updatePurchaseDeliveryStatus = async (
  purchaseId: string,
  data: TRequestPurchaseDeliveryStatus
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/update/deliveryStatus`, data)
    .then((res) => res.data);
};

export const updatePurchasePaymentMethod = async (
  purchaseId: string,
  data: TRequestPurchasePaymentMethod
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/payment-method`, data)
    .then((res) => res.data);
};

export const updatePurchaseShipping = async (
  purchaseId: string,
  data: TRequestPurchaseShipping
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/shipping`, data)
    .then((res) => res.data);
};

export const updatePurchaseClientLocation = async (
  purchaseId: string,
  data: TLocation
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/update/clientLocation`, data)
    .then((res) => res.data);
};

export const addPurchaseComment = async (
  purchaseId: string,
  data: TRequestPurchaseComment
) => {
  return getInstance()
    .post<TResponsePurchaseComment>(
      `${BASE_PATH}/${purchaseId}/comment`,
      data,
      {
        headers: {
          "x-log": "omit",
        },
      }
    )
    .then((res) => res.data);
};

export const updatePurchasePaymentVouchers = async (
  purchaseId: string,
  data: TRequestPurchasePaymentVouchers
) => {
  return getInstance()
    .post<TResponsePurchasePaymentVouchers>(
      `${BASE_PATH}/${purchaseId}/voucher`,
      data
    )
    .then((res) => res.data);
};

export const updatePurchasePaymentVouchersV2 = async (
  purchaseId: string,
  data: TRequestPurchasePaymentVouchers
) => {
  return getInstance()
    .post<TResponsePurchasePaymentVouchers>(
      `${BASE_PATH}/${purchaseId}/voucher/v2`,
      data
    )
    .then((res) => res.data);
};

export const deletePurchasePaymentVoucherV2 = async (
  purchaseId: string,
  data: string[]
) => {
  return getInstance()
    .delete<TResponsePurchasePaymentVouchers>(
      `${BASE_PATH}/${purchaseId}/voucher/v2`,
      { data }
    )
    .then((res) => res.data);
};

export const getPurchasePaymentVouchers = async (purchaseId: string) => {
  if (!isUUID(purchaseId)) {
    return;
  }
  return getInstance()
    .get<TResponsePurchasePaymentVouchers>(`${BASE_PATH}/${purchaseId}/voucher`)
    .then((res) => res.data);
};

export const updatePurchaseProductsAndTrackingCode = async (
  purchaseId: string,
  products: TRequestSubPurchaseProductUpdate[],
  trackingCode: string,
  deliveryProvider: string
) => {
  return getInstance()
    .put(`${BASE_PATH}/update/trackingCode`, {
      purchaseId,
      products,
      trackingCode,
      deliveryProvider,
    })
    .then((res) => res.data);
};

export const createQuote = async (
  data: {
    userType: string;
    products: TRequestPurchaseProduct[];
    couponCode: string;
    firstSale?: boolean;
    forVip?: boolean;
  },
  headers: AxiosRequestHeaders = {}
) => {
  return getInstance()
    .post<TPrePurchase>(`${BASE_PATH}/quote-price`, data, {
      headers: {
        "x-log": "omit",
        ...headers,
      },
    })
    .then((res) => res.data);
};

export const batchPurchasesStockSync = async () => {
  return getInstance().post("/api/stock-sync/purchases");
};

export const updatePurchasePriority = async (
  purchaseId: string,
  priority: number
) => {
  return getInstance()
    .put<TPurchase>(`${BASE_PATH}/${purchaseId}/update/priority`, { priority })
    .then((res) => res.data);
};

export const getPurchaseModification = async (purchaseId: string) => {
  return getInstance()
    .get(`${BASE_PATH}/${purchaseId}/modifications`)
    .then((res) => res.data);
};

export const retryPurchaseShipping = async (
  purchaseId: string,
  forced = false
) => {
  return getInstance()
    .post(`${BASE_PATH}/${purchaseId}/retry`, null, { params: { forced } })
    .then((res) => res.data);
};
