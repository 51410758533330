import { ViewList } from "@mui/icons-material";
import {
  Menu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
  useTranslate,
} from "react-admin";
import {
  ADMINROLES,
  ADMINS,
  BRANDS,
  CATEGORIES,
  COUPONS,
  COURSES,
  PERMISSIONS,
  PRODUCTS,
  PURCHASES,
  TICKETS,
  SELLERS,
  SUPPLIERS,
  SUPPLIER_PURCHASES,
  VOUCHERS,
  BANNERS,
  PRODUCT_GROUPS,
  DOCUMENTS,
  QUOTES,
  OFFERTS,
  SUPPLIER_PERMISSIONS,
} from "../config/resources";
import { useAdmin } from "../hooks/admin";
import { TPermissionId } from "@/resources/permissions/data";
import { country } from "@/config/country";

export const SideMenu = (props: any) => {
  const { hasPermission, shouldShowApp } = useAdmin();
  const [open] = useSidebarState();
  const t = useTranslate();

  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name]
  );

  if (!shouldShowApp) return null;

  const rawMenu: { resource: string; permission: TPermissionId }[] = [
    {
      resource: PURCHASES,
      permission: "purchase.list",
    },
    {
      resource: SUPPLIER_PURCHASES,
      permission: "purchase.list",
    },
    {
      resource: VOUCHERS,
      permission: "voucher.list",
    },
    {
      resource: PRODUCTS,
      permission: "product.list",
    },
    {
      resource: PRODUCT_GROUPS,
      permission: "productGroup.list",
    },
    {
      resource: CATEGORIES,
      permission: "category.list",
    },
    {
      resource: SELLERS,
      permission: "seller.list",
    },
    {
      resource: BRANDS,
      permission: "brand.list",
    },
    {
      resource: SUPPLIERS,
      permission: "supplier.list",
    },
    {
      resource: TICKETS,
      permission: "ticket.list",
    },
    {
      resource: DOCUMENTS,
      permission: "document.list",
    },
    {
      resource: BANNERS,
      permission: "banner.list",
    },
    {
      resource: ADMINS,
      permission: "admin.list",
    },
    {
      resource: ADMINROLES,
      permission: "adminrole.list",
    },
    {
      resource: PERMISSIONS,
      permission: "permission.list",
    },
    {
      resource: SUPPLIER_PERMISSIONS,
      permission: "supplier-permission.list",
    },
  ];

  const resourcesAvailablesByCountry = rawMenu.filter(
    (item) => !country.hiddenResources?.includes(item.resource)
  );

  const permittedResources = resourcesAvailablesByCountry.filter(
    (item) => !item.permission || hasPermission(item.permission)
  );

  const availableResources = permittedResources.map(
    (item) => resources.find((r) => r.name === item.resource)!
  );

  return (
    <Menu {...props} sx={{ mb: "120px" }}>
      {availableResources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            resource.options?.label ||
            (resource.name === COURSES
              ? "Educación"
              : t(`resources.${resource.name}.name`, { smart_count: 2 }))
          }
          leftIcon={resource.icon ? <resource.icon /> : <ViewList />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
    </Menu>
  );
};
