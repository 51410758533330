import { type FC, useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  email,
  FileField,
  FileInput,
  FormTab,
  number,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";
import {
  type TSupplier,
  getAccountGroups,
  getAccountGroupsSupplier,
} from "@/api";
import { availableSeller } from "../shared/available-user";
import { availableAuthEmail } from "../shared/available-auth-email";
import { country, phonePrefixChoices } from "@/config/country";
import { OptimizedImageInput } from "@/components/elements/OptimizedImageInput";

const SupplierAccountGroupInput: FC<any> = ({ isEdit }) => {
  const { setValue } = useFormContext();
  const record = useRecordContext();

  const [choices, setChoices] = useState<any[]>([]);

  useEffect(() => {
    if (country.code === "co") {
      getAccountGroups()
        .then((data) => setChoices(data))
        .catch(() => setChoices([]));
      if (isEdit) {
        getAccountGroupsSupplier().then((groups) => {
          const group = groups.find((g) => g.supplierId === record.id);
          setValue("accountGroupId", group?.siigoAccountGroupId);
        });
      }
    }
  }, []);

  return choices.length ? (
    <AutocompleteInput
      label="Grupo de cuenta"
      source="accountGroupId"
      choices={choices.filter((c: any) => c.active)}
      disabled={isEdit && record.accountGroupId}
      validate={required()}
      fullWidth
    />
  ) : (
    <TextInput
      label="Grupo de cuenta"
      source="accountGroupId"
      disabled={isEdit && record.accountGroupId}
      fullWidth
    />
  );
};

const SupplierEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const SupplierForm: FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const supplier = useRecordContext<TSupplier>();

  return (
    <TabbedForm toolbar={<SupplierEditToolbar />} warnWhenUnsavedChanges>
      <FormTab label="Principal">
        <Grid container spacing={4}>
          {isEdit && (
            <Grid item xs={4}>
              <TextInput source="id" disabled fullWidth />
            </Grid>
          )}
          <Grid item xs={4}>
            <TextInput
              source="companyId"
              disabled={isEdit}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="name" validate={required()} fullWidth />
          </Grid>

          <Grid item xs={4}>
            <TextInput source="publicName" fullWidth />
          </Grid>

          <Grid item xs={4}>
            <TextInput
              source="email"
              validate={[
                required(),
                email(),
                availableAuthEmail(supplier?.email),
                availableSeller("email", supplier?.email),
              ]}
              disabled={isEdit}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="communicationChannel"
              validate={[email()]}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="contactName" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="socialReason" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              source="phonePrefix"
              choices={phonePrefixChoices}
              validate={required()}
              disabled={isEdit}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="phone"
              validate={[required(), number("Solo números")]}
              inputMode="numeric"
              disabled={isEdit}
              fullWidth
            />
          </Grid>
          {country.code === "co" && (
            <Grid item xs={4}>
              <SupplierAccountGroupInput isEdit={isEdit} />
            </Grid>
          )}

          <Grid item xs={12}>
            <OptimizedImageInput
              source="logo"
              width={200}
              height={200}
              format={(v) => (typeof v === "string" ? { src: v } : v)}
            />
          </Grid>

          <Grid item xs={12}>
            <FileInput
              source="digitalDocument"
              maxSize={5000000}
              helperText="Archivo de menos de 5MB. Si el archivo no carga es porque supera el peso de 5MB."
              format={(v: any) => {
                if (v?.rawFile) {
                  const { rawFile } = v;
                  const blob = rawFile.slice(0, rawFile.size, rawFile.type);
                  v.rawFile = new File(
                    [blob],
                    Date.now() + "." + rawFile.type?.split("/")[1],
                    { type: blob.type }
                  );
                }
                return v;
              }}
              fullWidth
            >
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
          </Grid>
          <TextInput
            source="source"
            defaultValue={"react_backoffice"}
            type="hidden"
            sx={{ display: "none" }}
          />
          <Grid item xs={4}>
            <BooleanInput
              source="multiSupplierProducts"
              helperText="Distribuidor múltiple"
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput source="status" defaultValue={true} />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              source="ownUponDelivery"
              label="El proveedor puede usar logística propia en pedidos contra entrega"
              defaultValue={false}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              source="manualInvoice"
              label="El proveedor factura sus pedidos"
              defaultValue={false}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              source="showPricingInfoOnInvoice"
              label="Mostrar precios en remisiónes"
              defaultValue={false}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              source="showClientInfoOnInvoice"
              label="Mostrar información del cliente en remisiones"
              defaultValue={false}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              source="productsAdministration"
              label="El proveedor administra sus productos"
              defaultValue={false}
            />
          </Grid>
          <Grid item xs={4}>
            <BooleanInput
              source="canChangeProductQuantity"
              label="El proveedor puede modificar las unidades de sus pedidos"
              defaultValue={false}
            />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  );
};
