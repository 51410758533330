import { Tooltip } from "@mui/material";
import { AxiosError, retryPurchaseShipping, updateSupplierOrder } from "@/api";
import { useState } from "react";
import { Button, useNotify, useRecordContext, useRefresh } from "react-admin";
import { DISABLED_TIMEOUT } from "../../purchases/show/actions/create-subpurchase";

export const RetrySupplierShipping = () => {
  const [retrying, setRetrying] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = async () => {
    setRetrying(true);
    const { amountBoxes, warehouseId, id } = record;
    try {
      await updateSupplierOrder(id as string, "approve", {
        amountBoxes,
        warehouseId,
      });
      notify("Esperando asignación de guías", {
        type: "success",
      });
      refresh();
      setTimeout(() => {
        setRetrying(false);
        refresh();
      }, DISABLED_TIMEOUT);
    } catch (error) {
      if (error instanceof AxiosError) {
        notify(error.response?.data?.message ?? error.message, {
          type: "error",
        });
      } else {
        notify("No se pudo reintentar el envío", {
          type: "error",
        });
      }
    } finally {
      setRetrying(false);
    }
  };

  if (!["IN_PROCESS"].includes(record?.supplierStatus?.toUpperCase())) {
    return null;
  }

  return (
    <>
      <div style={{ marginBottom: "1rem" }}>
        <Tooltip title="Cancelar pedido">
          <div>
            <Button
              onClick={handleClick}
              variant="contained"
              size="small"
              label="Reintentar envio"
              disabled={retrying}
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
};
